<template>
  <div id="import-file-upload-modal">

    <b-modal size="lg" ref="import-result-error" hide-footer>
      <b-table
        id="import-result-table"
        :items="errors"
        :fields="error_headers"
        class="table-striped"
        head-variant="light"
      >

      </b-table>
    </b-modal>


    <b-modal ref="file-upload-modal" hide-footer>
      <b-row class="mt-8">
        <b-col>
          <div class="boxed-select">
            <p class="boxed-header mb-4">{{ $t('PAGES.FAITH.UPLOAD_SKATTEVERKET_FILE') }}</p>
            <div class="checkbox-list">

              <div class="d-flex align-items-center mb-6">
                <label class="radio radio-outline radio-success mr-5">
                  <input
                    type="radio"
                    v-model="form.type"
                    name="skv_file_type"
                    :value="'SKV_IMPORT_INCOME_PERSONNR_ADVANCE'"
                  />
                  <span class="mr-2"></span>{{ $t('PAGES.FAITH.SKV_IMPORT_INCOME_PERSONNR_ADVANCE') }}
                </label>
              </div>

              <div class="d-flex align-items-center mb-6">
                <label class="radio radio-outline radio-success mr-5">
                  <input
                    type="radio"
                    v-model="form.type"
                    name="skv_file_type"
                    :value="'SKV_IMPORT_INCOME_PERSONNR_SETTLED'"
                  />
                  <span class="mr-2"></span>{{ $t('PAGES.FAITH.SKV_IMPORT_INCOME_PERSONNR_SETTLED') }}
                </label>
              </div>

            </div>
          </div>

        </b-col>
      </b-row>

      <b-row class="mt-8">
        <b-col>
          <b-form-group id="input-group-period" :label="$t('PERIOD.PERIOD')" label-for="input-period">
            <b-form-select
              id="period"
              v-model="form.period_id"
              :options="period_options"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-8">
        <b-col>
          <b-button type="button" variant="primary" @click.prevent="select_file()"
            >{{ $t('PAGES.IMPORT.UPLOAD_FILE') }}</b-button
          >
        </b-col>
      </b-row>

      <b-row class="mt-8">
        <b-col>
          <b-form-group v-if="form.file" id="input-group-filename" :label="$t('FILE.FILENAME')" label-for="input-filename">
            <b-form-input
              id="filename"
              v-model="form.file.name"
              :disabled="true"
            ></b-form-input>
          </b-form-group>

          <input hidden type="file" id="file" ref="file" v-on:change="handle_file_upload()" accept="*" />

        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <RightModalSaveAndCloseButtons
            :text="$t('COMMON.SAVE')"
            :spin="true"
            @clicked="on_submit"
            @close="$refs['file-upload-modal'].hide()"
            ref="saveButton"
          />
        </b-col>
      </b-row>


    </b-modal>


  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'import-file-upload-modal',
  mixins: [ toasts ],
  emits: ['on_import_file_created'],
  components: {
    RightModalSaveAndCloseButtons
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods', 'companies']),

    selected_period_name() {
      const period = this.periods.find(item => item.id === this.currentPeriodId);

      return period ? period.name : '-';
    },

    period_options() {
      return this.periods.map(item => ({ value: item.id, text: item.name }));
    },

    error_headers() {
      return [
        {
          visible: true,
          key: 'row',
          label: this.$t('PAGES.FAITH.IMPORT_ERROR_ROW'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: true,
          key: 'company.cnum',
          label: this.$t('COMPANY.CNUM'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: true,
          key: 'period.name',
          label: this.$t('PERIOD.PERIOD'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: true,
          key: 'error',
          label: this.$t('PAGES.FAITH.IMPORT_ERROR_MESSAGE'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow',
          formatter: (_, __, item) => {
            if (item.error) {
              return this.$t('PAGES.FAITH.IMPORT_FILE_ERRORS.' + item.error.toUpperCase(), { company: item.company.name + ' ' + item.company.cnum, period: item.period.name });
            }
          }
        },
      ]
    }

  },
  mounted() {

  },

  data() {
    return {
      form: {
        period_id: null,
        file_id: null,
        type: null,
        file: null
      },

      file_upload: null,

      errors: [],



      file_categories: [
        {
          type: 'SKV_IMPORT_INCOME_PERSONNR_ADVANCE',
          period: {},
          created_at: '-',
          user: {},
          history: [],
          upload_file: ''
        },
        {
          type: 'SKV_IMPORT_INCOME_PERSONNR_SETTLED',
          period: {},
          created_at: '-',
          user: {},
          history: [],
          upload_file: ''
        },

      ],
      files: [

      ]
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    on_submit() {
      if (!this.form.file || !this.form.type || !this.form.period_id) {

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.MUST_SELECT_FILE_AND_PERIOD'));

        this.$refs['saveButton'].stop();
        return;
      }

      this.submit();
    },

    show() {
      this.$refs['file-upload-modal'].show();
    },

    hide() {
      this.$refs['file-upload-modal'].hide();
    },

    set_type(type) {
      this.form.type = type;
    },


    async submit() {

      try {

        const res = await axios.post(`/skv/import/income/${this.form.file_id}`, this.form);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.FAITH.FILE_IMPORTED'));
          this.$emit('on_import_file_created', res.data);

          this.form = {
            period_id: null,
            file_id: null,
            type: null,
            file: null
          };

          this.$refs['saveButton'].stop();
          this.$refs['file-upload-modal'].hide();

          return;
        }
        else if (res.status === 409) {
          this.errors = res.data.error;
          this.$refs['saveButton'].stop();

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_FILE_IMPORT'));

          this.form = {
            period_id: null,
            file_id: null,
            type: null,
            file: null
          };

          this.$refs['file-upload-modal'].hide();

          this.$refs['import-result-error'].show();
          return;
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_FILE_IMPORT'));
      }

      this.$refs['saveButton'].stop();
    },

    on_upload_file_clicked(type) {
      this.file_type_for_upload = type;

      this.select_file();
    },

    handle_file_upload() {
      this.file_upload = this.$refs.file.files[0];

      if (this.file_upload.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.upload_file();
    },
    select_file() {
      this.$refs.file.click();
    },
    upload_file() {
      const loader = this.$loading.show();

      let formData = new FormData();
      formData.append('file', this.file_upload);

      formData.append('file_source', 'files');
      formData.append('file_type', this.file_type_for_upload);
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(async res => {
          loader & loader.hide();
          //this.toastr('success', this.$t('COMMON.OK'), 'Filen laddades upp');

          const file_id = res.data.fileobjs[0].file_id;

          this.form.file_id = file_id;
          this.form.file = res.data.fileobjs[0];
        })
        .catch(err => {
          loader & loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_UPLOAD'));
        });
    },

  }
};
</script>
