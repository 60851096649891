<template>
  <div id="export-files-page">
    <ExportFilesTable
      class="mt-8"
      :files="file_categories"
      @on_generate_file_clicked="on_generate_file_clicked"
    />

    <JobProgressInformation
      v-if="export_job" 
      :job_id="export_job.id"

      @finished="job_finished"

      ref="progress-popup"
    />
  </div>
</template>

<script>
// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import ExportFilesTable from '@/view/pages/ml/faith/export/ExportFilesTable.vue';
import dayjs from 'dayjs';

import GenericProgressPopup from '@/view/components/GenericProgressPopup.vue';
import JobProgressInformation from '@/view/pages/ml/jobs/JobProgressInformation.vue';


export default {
  name: 'export-files-page',
  mixins: [ toasts ],
  components: {
    ExportFilesTable,
    GenericProgressPopup,
    JobProgressInformation
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods']),

    selected_period_name() {
      const period = this.periods.find(item => item.id === this.currentPeriodId);

      return period ? period.name : '-';
    },

    selected_period() {
      return this.periods.find(item => item.id === this.currentPeriodId);
    },

  },
  mounted() {
    this.load_files(this.currentPeriodId);
  },
  data() {
    return {

      export_job: null,

      file_categories: [
        {
          type: 'SKV_EXPORT_REGISTRATIONS',
          period: {},
          created_at: '-',
          user: {},
          history: [],
          generate_file: ''
        },
        {
          type: 'SKV_EXPORT_INCOME_PERSONNR_ADVANCE',
          period: {},
          created_at: '-',
          user: {},
          history: [],
          generate_file: ''
        },
        {
          type: 'SKV_EXPORT_INCOME_PERSONNR_SETTLED',
          period: {},
          created_at: '-',
          user: {},
          history: [],
          generate_file: ''
        }
      ],
      files: [

      ]
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
      this.load_files(this.currentPeriodId);
    }

  },
  methods: {

    job_finished(job) {
      this.export_job = null;

      if (this.$refs['progress-popup']) {
        this.$refs['progress-popup'].hide();
      }

      this.load_files(this.currentPeriodId);

    },

    on_generate_file_clicked(file_type) {

      let url = '';
      let body = {};

      const selected_period = this.selected_period;

      switch (file_type) {
        case 'SKV_EXPORT_REGISTRATIONS': {
          url = '/skv/export/member/registration';
          body = {
            skv_period_id: this.currentPeriodId
          };
          break;
        }
        case 'SKV_EXPORT_INCOME_PERSONNR_ADVANCE': {
          url = '/skv/export/income/personnr';
          body.period_id = selected_period.id;
          body.is_advance = true;
          break;
        }
        case 'SKV_EXPORT_INCOME_PERSONNR_SETTLED': {
          url = '/skv/export/income/personnr';
          body.period_id = selected_period.id;
          body.is_advance = false;
          break;
        }
        default: {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.ERROR'));
          return;
        }
      }

      this.export_file(url, file_type, body);
    },

    async export_file(url, type, body) {

      const loader = this.$loading.show();

      try {
        const res = await axios.post(url, body);

        if (res.status !== 201) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_EXPORT_FILE'));
          loader & loader.hide();
          return;
        }

        if (type === 'SKV_EXPORT_INCOME_PERSONNR_ADVANCE' || type === 'SKV_EXPORT_INCOME_PERSONNR_SETTLED') {
          this.export_job = res.data;

          this.$nextTick(()=>{

            if (this.$refs['progress-popup']) {
              this.$refs['progress-popup'].show();
            }

          });

        }

        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.FAITH.FILE_EXPORTED'));

        this.load_files(this.currentPeriodId);

        loader & loader.hide();
      }
      catch (err) {
        console.error('export_file', err);
        loader & loader.hide();
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_EXPORT_FILE'));
      }
    },

    async load_files(period_id) {
      try {
        const res = await axios.get(`/skv/files/${period_id}`);

        if (res.status !== 200) {
          this.toastr('danger', this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_LOAD_FILES')));
          return;
        }

        this.files = res.data;

        const period = this.periods.find(item => item.id === period_id);

        for (const category of this.file_categories) {
          category.period = period;
          category.user_name = category.user ? (category.user?.firstname + ' ' + category.user?.lastname) : '-';
          category.history = this.files.filter(item => item.type === category.type);
          category.history.sort((a, b) => {
            if (a.created_at < b.created_at) {
              return 1;
            }
            return -1;
          });
          category.created_at = category.history.length === 0 ? '-' : category.history[0].created_at;
        }

      }
      catch (err) {
        console.error('load_files error', err);
      }
    },



  }
};
</script>
