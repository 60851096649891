<template>
  <div id="company-periods-overview-page">

    <CompanyEditModal
      ref="company-edit-modal"
    />

    <CompanyPeriodEditModal
      ref="company-period-edit-modal"
      v-if="selected_company_period"
      v-bind:value="selected_company_period" v-on:update:company_period="updated_company_period"
    />

    <div>
      <CompanyPeriodTable
        ref="company-period-table"
        :items="company_periods"
        :show_create="false"
        @select_company="select_company"
        @select_company_period="select_company_period"
        @create_company_period="create_company_period"
        @delete_company_period="delete_company_period"
        @create_company_period_for_company="create_company_period_for_company"
        @update_company_period="update_company_period"
        @text_changed="company_period_text_changed"

      />

      <div v-if="show_create_all_periods" class="mt-4">

        <b-row>
          <b-col class="text-center">
            <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
              <div class="alert-icon"><i class="flaticon-warning"></i></div>
              <div class="alert-text">{{ $t('COMPANY_PERIOD.NO_COMPANY_PERIODS', { period: this.selected_period_name }) }}</div>
            </div>

            <div v-if="missing_period_two_years_back" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
              <div class="alert-icon"><i class="flaticon-warning"></i></div>
              <div class="alert-text">{{ $t('COMPANY_PERIOD.MISSING_PREVIOUS_PERIOD', { period: this.selected_period_name }) }}</div>
            </div>


            <b-button v-if="!missing_period_two_years_back" @click.prevent="create_for_all_companies_clicked" variant="primary">{{ $t('COMPANY_PERIOD.CREATE_FOR_ALL', { period: this.selected_period_name }) }}</b-button>
          </b-col>
        </b-row>

      </div>
    </div>

  </div>
</template>

<script>
// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import CompanyPeriodTable from '@/view/pages/ml/faith/company_period/CompanyPeriodTable.vue';
import CompanyEditModal from '@/view/pages/ml/faith/company_period/CompanyEditModal.vue';
import CompanyPeriodEditModal from '@/view/pages/ml/faith/company_period/CompanyPeriodEditModal.vue';

export default {
  name: 'company-periods-overview-page',
  mixins: [ toasts ],
  components: {
    CompanyPeriodTable,
    CompanyEditModal,
    CompanyPeriodEditModal
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods']),

    selected_period_name() {
      const period = this.periods.find(item => item.id === this.currentPeriodId);

      return period ? period.name : '-';
    },

    show_create_all_periods() {
      return this.company_periods.length === 0;
    },

    missing_period_two_years_back() {
      const prev = this.find_period_before_period(this.currentPeriodId);

      if (!prev) {
        return true;
      }

      const prev_prev = this.find_period_before_period(prev.id);

      if (!prev_prev) {
        return true;
      }

      return false;
    },
  },
  mounted() {

    this.load_company_periods_for_period(this.currentPeriodId);
  },
  data() {
    return {

      copy: [],

      company_periods: [],
      selected_company_period: null,
      selected_company: null
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
      this.load_company_periods_for_period(this.currentPeriodId);
    }

  },
  methods: {

    company_period_text_changed(text) {
      this.company_periods = this.copy.filter(item => {
        return item.company.name.toLowerCase().includes(text.toLowerCase());
      });
    },

    refresh() {
      this.load_company_periods_for_period(this.currentPeriodId);
    },

    update_company_period(company_period) {
      const index = this.company_periods.findIndex(item => item.id === company_period.id);

      this.company_periods[index] = company_period;
      // Vue3 compatability for Vue.set
      this.company_periods = [...this.company_periods];

      this.$nextTick(()=>{
        this.$refs['company-period-table'].expand_row(company_period.id);
      });

    },

    async create_company_period_for_company(company_id) {
      const loader = this.$loading.show();

      try {
        const company_period_response = await axios.post(`/company_period/period/${this.currentPeriodId}/company/${company_id}`);

        if (company_period_response.status !== 201) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_CREATE'));

          loader & loader.hide();
          return;
        }

        const index = this.company_periods.findIndex(item => item.company.company_id === company_id);

        this.company_periods[index] = company_period_response.data;
        // Vue3 compatability for Vue.set
        this.company_periods = [...this.company_periods];

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMPANY_PERIOD.CREATED'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_CREATE'));
        console.error('create_company_period_for_company error:', err);
      }

      loader & loader.hide();
    },

    find_period_before_period(period_id) {
      const period = this.periods.find(item => item.next_period_id === period_id);

      return period;
    },

    async select_company(company_id) {
      const loader = this.$loading.show();

      try {

        const response = await axios.get(`/company/${company_id}`);

        if (response.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY.UNABLE_GET'));

          loader & loader.hide();

          return;
        }

        this.selected_company = response.data;

        // todo: show gets only the company_id, because we dont have access to the company right now, good design?
        // A better way is probably to GET the company at this point and bind it to the modal, the same way we do
        // with company-period-edit-modal
        this.$refs['company-edit-modal'].show(company_id);
      }
      catch (err) {
        console.error('select_company error', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY.UNABLE_GET'));
      }

      loader & loader.hide();
    },

    async select_company_period(company_period_id) {

      // assign the selected company_period, v-if will trigger next frame render
      this.selected_company_period = this.company_periods.find(item => item.id === company_period_id);

      // so we need to wait for next tick
      this.$nextTick(() => {
        // now company-period-edit-modal should be rendered, so we can call the reference
        this.$refs['company-period-edit-modal'].show();
      });

    },

    async updated_company_period(company_period) {
      const loader = this.$loading.show();

      try {
        const response = await axios.put(`/company_period/${company_period.id}`, company_period);

        if (response.status !== 204) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_UPDATE'));

          loader & loader.hide();

          return;
        }

        const index = this.company_periods.findIndex(item => item.id === company_period);

        if (index < 0) {
          console.error(`company_period ${company_period.id} not found in company_periods`);

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_UPDATE'));
        }
        else {
          this.company_periods[index] = company_period;
          // Vue3 compatability for Vue.set
          this.company_periods = [...this.company_periods];

          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMPANY_PERIOD.UPDATED'));
        }
      }
      catch (err) {
        console.error('updated_company_period error', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_UPDATE'));
      }

      loader & loader.hide();
    },

    create_company_period() {},

    async delete_company_period(company_period_id) {
      const loader = this.$loading.show();

      try {
        const company_period_response = await axios.delete(`/company_period/${company_period_id}`);

        if (company_period_response.status !== 204) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_DELETE'));

          loader & loader.hide();
          return;
        }

        this.company_periods = this.company_periods.filter(item => item.id !== company_period_id);

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMPANY_PERIOD.DELETED'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_DELETE'));
        console.error('delete_company_period error:', err);
      }

      loader & loader.hide();
    },

    create_for_all_companies_clicked() {
      // should this component own the data? probably
      this.create_company_periods(this.currentPeriodId);
    },

    updated_company_period(company_period) {

    },

    created_company_period(company_period) {

    },


    async create_company_periods(period_id) {
      const loader = this.$loading.show();

      try {
        const company_periods_response = await axios.post(`/company_period/period/${period_id}`);

        if (company_periods_response.status !== 201) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_CREATE'));

          loader & loader.hide();
          return;
        }

        this.company_periods = company_periods_response.data;

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMPANY_PERIOD.CREATED'));
      }
      catch (err) {
        console.error('create_company_periods error:', err);
      }

      loader & loader.hide();
    },

    // load all the company periods (financial year) with given period_id
    async load_company_periods_for_period(period_id) {
      const loader = this.$loading.show();

      try {
        const company_periods_response = await axios.get(`/company_period/period/${period_id}?include_missing=true`);

        if (company_periods_response.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_LIST'));

          loader & loader.hide();
          return;
        }

        this.company_periods = company_periods_response.data;
        this.copy = this.company_periods;
      }
      catch (err) {
        console.error('load_company_periods_for_period error:', err);
      }

      loader & loader.hide();
    }

  }
};
</script>
