<template>
  <div>

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">

        <div class="p-8">
          <b-row>
            <b-col>
              <tip-label :tip="$t('PAGES.FAITH.TIP_INTAG')">
                <h5 class="mb-4 mt-4">{{$t('PAGES.FAITH.HEADER_INTAG')}}</h5>
              </tip-label>
            </b-col>
          </b-row>
        </div>


        <div class="pl-16">
          <b-row>
            <b-col>
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success mb-7">
                <input
                  type="checkbox"
                  name=""
                  v-model="settings.skv_include_minors"
                  @click="settings.skv_include_minors = !settings.skv_include_minors"
                />
                <span class='mr-3'></span>
                {{ $t('PAGES.FAITH.SKV_INCLUDE_MINORS') }}
              </label>
            </b-col>
          </b-row>
        </div>


        <div class="p-8">
          <b-row>
            <b-col>
              <tip-label :tip="$t('PAGES.FAITH.TIP_REDOVISNINGSRAKNING')">
                <h5 class="mb-4 mt-4">{{$t('PAGES.FAITH.HEADER_REDOVISNINGSRAKNING')}}</h5>
              </tip-label>
            </b-col>
          </b-row>
        </div>

        <div class="pl-16">

          <b-row>
            <b-col md="6">
              <b-form-group id="group-left_header">
                <tip-label class="mb-2" for="field-left_header" asterix>{{$t('PAGES.FAITH.REDOVISNINGSRAKNING.LEFT_HEADER')}}</tip-label>
                <b-form-input
                  id="field-left_header"
                  v-model="redovisningsrakning.left_header"
                  type="text"
                  trim
                  :state="left_header_state"
                />
                <b-form-invalid-feedback>{{$t('PAGES.FAITH.REDOVISNINGSRAKNING.INVALID_LEFT_HEADER')}}</b-form-invalid-feedback>
                <b-form-text> {{ $t('PAGES.FAITH.REDOVISNINGSRAKNING.NEW_LINE_INFO') }}</b-form-text>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group id="group-right_header">
                <tip-label class="mb-2" for="field-right_header" asterix>{{$t('PAGES.FAITH.REDOVISNINGSRAKNING.RIGHT_HEADER')}}</tip-label>
                <b-form-input
                  id="field-right_header"
                  v-model="redovisningsrakning.right_header"
                  type="text"
                  trim
                  :state="right_header_state"
                />
                <b-form-invalid-feedback>{{$t('PAGES.FAITH.REDOVISNINGSRAKNING.INVALID_RIGHT_HEADER')}}</b-form-invalid-feedback>
                <b-form-text> {{ $t('PAGES.FAITH.REDOVISNINGSRAKNING.NEW_LINE_INFO') }}</b-form-text>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group id="group-signed_name">
                <tip-label class="mb-2" for="field-signed_name" asterix>{{$t('PAGES.FAITH.REDOVISNINGSRAKNING.SIGNED_NAME')}}</tip-label>
                <b-form-input
                  id="field-signed_name"
                  v-model="redovisningsrakning.signed_name"
                  type="text"
                  trim
                  :state="signed_name_state"
                />
                <b-form-invalid-feedback>{{$t('PAGES.FAITH.REDOVISNINGSRAKNING.INVALID_SIGNED_NAME')}}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group id="group-gdpr_contact_name">
                <tip-label class="mb-2" for="field-gdpr_contact_name" asterix>{{$t('PAGES.FAITH.GDPR_CONTACT_NAME')}}</tip-label>
                <b-form-input
                  id="field-gdpr_contact_name"
                  v-model="settings.gdpr_contact_name"
                  :state="gdpr_contact_name_state"
                  type="text"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group id="group-gdpr_contact_email">
                <tip-label class="mb-2" for="field-gdpr_contact_email" asterix>{{$t('PAGES.FAITH.GDPR_CONTACT_EMAIL')}}</tip-label>
                <b-form-input
                  id="field-gdpr_contact_email"
                  v-model="settings.gdpr_contact_email"
                  :state="gdpr_contact_email_state"
                  type="text"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>

        </div>

        <div>

          <div class="p-8">
            <b-row>
              <b-col>
                <tip-label :tip="$t('PAGES.FAITH.TIP_FEES')">
                  <h5 class="mb-4 mt-4">{{$t('PAGES.FAITH.HEADER_FEES')}}</h5>
                </tip-label>
              </b-col>
            </b-row>
          </div>

          <div class="ml-16">
            <b-row>
              <b-col md="3">
                <b-form-group
                  id="input-group-mem_pct"

                  label-for="input-mem_pct"
                >
                  <tip-label class="mb-2" for="field-signed_name" asterix>{{$t('PAGES.FAITH.MEM_PCT')}}</tip-label>
                  <b-form-input
                    id="input-mem_pct"
                    v-model="settings.mem_pct"
                    :state="mem_pct_state"
                  />
                  <b-form-text>{{$t('PAGES.FAITH.MUST_BE_ENGLISH_FLOAT')}}</b-form-text>
                </b-form-group>

              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  id="input-group-fee_pct"

                  label-for="input-fee_pct"
                >
                  <tip-label class="mb-2" for="field-signed_name" asterix>{{$t('PAGES.FAITH.FEE_PCT')}}</tip-label>
                    <b-form-input
                      id="input-fee_pct"
                      v-model="settings.fee_pct"
                      :state="fee_pct_state"
                    />
                    <b-form-text>{{$t('PAGES.FAITH.MUST_BE_ENGLISH_FLOAT')}}</b-form-text>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  id="input-group-adm_fee"
                  label-for="input-adm_fee"
                >
                  <tip-label class="mb-2" for="field-signed_name" asterix>{{$t('PAGES.FAITH.ADM_FEE')}}</tip-label>
                  <b-form-input
                    id="input-adm_fee"
                    v-model="settings.adm_fee"
                    :state="adm_fee_state"
                  />
                  <b-form-invalid-feedback>{{ $t('VALIDATION.ADM_FEE') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  id="input-group-mem_fee"

                  label-for="input-mem_fee"
                >
                  <tip-label class="mb-2" for="field-signed_name" asterix>{{$t('PAGES.FAITH.MEM_FEE')}}</tip-label>
                  <b-form-input
                    id="input-mem_fee"
                    v-model="settings.mem_fee"
                    :state="mem_fee_state"
                  />
                  <b-form-invalid-feedback>{{ $t('VALIDATION.MEM_FEE') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

          </div>

        </div>

        <div class="p-8">

          <RightSaveButton
            ref="save-button"
            type="submit"
            :text="$t('COMMON.SAVE')"
            @clicked="on_submit"
          />

        </div>
      </b-form>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import ColorPickerInput from '@/view/components/ColorPickerInput';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { useVuelidate } from '@vuelidate/core';
import { required, minValue, maxValue } from '@vuelidate/validators';
import { validate_state } from '@/core/services/validations.service';

// extend('required', required);
// extend('confirmed', confirmed);
// extend('email', email);
// extend('min', min);
// extend('max', max);
// extend('min_value', min_value);
// extend('max_value', max_value);

export default {
  name: 'FaithSystemSettings',

  components: {
    RightSaveButton,
    TipLabel,
    RequiredFieldsInfo,
    ColorPickerInput
  },

  mixins: [ toasts ],

  computed: {
    ...mapGetters(['currentCompanyId']),
    left_header_state() {
      return validate_state({ dirty: this.v$.redovisningsrakning.left_header.$dirty , error: this.v$.redovisningsrakning.left_header.$error });
    },
    right_header_state() {
      return validate_state({ dirty: this.v$.redovisningsrakning.right_header.$dirty , error: this.v$.redovisningsrakning.right_header.$error });
    },
    signed_name_state() {
      return validate_state({ dirty: this.v$.redovisningsrakning.signed_name.$dirty , error: this.v$.redovisningsrakning.signed_name.$error });
    },
    adm_fee_state() {
      return validate_state({ dirty: this.v$.settings.adm_fee.$dirty , error: this.v$.settings.adm_fee.$error });
    },
    mem_fee_state() {
      return validate_state({ dirty: this.v$.settings.mem_fee.$dirty , error: this.v$.settings.mem_fee.$error });
    },
    mem_pct_state() {
      return validate_state({ dirty: this.v$.settings.mem_pct.$dirty , error: this.v$.settings.mem_pct.$error });
    },
    fee_pct_state() {
      return validate_state({ dirty: this.v$.settings.fee_pct.$dirty , error: this.v$.settings.fee_pct.$error });
    },

    gdpr_contact_name_state() {
      return validate_state({ dirty: this.v$.settings.gdpr_contact_name.$dirty , error: this.v$.settings.gdpr_contact_name.$error });
    },

    gdpr_contact_email_state() {
      return validate_state({ dirty: this.v$.settings.gdpr_contact_email.$dirty , error: this.v$.settings.gdpr_contact_email.$error });
    },
  },

  watch: {},
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      redovisningsrakning: {
        left_header: { required },
        right_header: { required },
        signed_name: { required }
      },
      settings: {
        mem_fee: { required, minValue: minValue(0), maxValue: maxValue(9999999999999) },
        mem_pct: { required, minValue: minValue(0), maxValue: maxValue(100) },
        fee_pct: { required, minValue: minValue(0), maxValue: maxValue(100) },
        adm_fee: { required, minValue: minValue(0), maxValue: maxValue(9999999999999)},
        gdpr_contact_name: { required },
        gdpr_contact_email: { required }
      },
    }
  },
  methods: {
    validate_state,
    async on_submit() {
      const valid = await this.v$.$validate();

      if (valid) {
        await this.save_data();
      }
      else {
        this.v$.$touch()
      }
    },

    async load_settings() {
      try {
        const response = await axios.get(`/company/${this.currentCompanyId}`);
        this.redovisningsrakning = response.data.company_settings.redovisningsrakning;

        this.settings = response.data.company_settings;

        if (!this.redovisningsrakning) {
          this.redovisningsrakning = {
            left_header: '',
            right_header: '',
            signed_name: ''
          }
        }

      } catch (error) {
        this.toastr('danger', this.$t('COMMON.ERROR'), error);
      }
    },

    async save_data() {
      try {
        const response = await axios.put(`/company/company_settings/${this.currentCompanyId}`, {
          redovisningsrakning: this.redovisningsrakning,
          mem_pct: this.settings.mem_pct,
          fee_pct: this.settings.fee_pct,
          adm_fee: this.settings.adm_fee,
          mem_fee: this.settings.mem_fee,
          move_reg_after: this.settings.move_reg_after,
          skv_include_minors: this.settings.skv_include_minors,
          gdpr_contact_name: this.settings.gdpr_contact_name,
          gdpr_contact_email: this.settings.gdpr_contact_email
        });

        if (response.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }

        this.$refs['save-button'].stop();
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));

        this.$refs['save-button'].stop();
      }
    },
  },

  mounted() {
    this.load_settings();
  },

  data() {
    return {
      redovisningsrakning: {
        left_header: null,
        right_header: null,
        signed_name: null
      },
      settings: {
        gdpr_contact_email: '',
        gdpr_contact_name: '',
        mem_fee: 0,
        mem_pct: 0,
        fee_pct: 0,
        adm_pct: 0,
        move_reg_after: null,
        skv_include_minors: false,
      }
    };
  }

};

</script>
