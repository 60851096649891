<template>
  <div>
<!-- table-head-custom table-vertical-center table-head-bg table-borderless -->
    <b-table
      id="export-files-table"
      class="table-striped mh-100 table-head-custom table-head-bg"
      :fields="headers"
      :items="files"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      sticky-header
      responsive
      striped
      selectable
      hover
      @row-clicked="handle_row_clicked"
    >
      <!-- this is a built in exand slot which will insert a new row inside the table when clicking on a row -->
      <template style="padding: 0px;" #row-details="row">

        <ExportFilesRowDetails
          v-bind:value="row.item"
        />

      </template>
      <template #cell(expand)="data">
        <span class="svg-icon svg-icon-sm text-primary">
          <inline-svg
            :src="data.detailsShowing ? '/assets/svg/chevron-up.svg' : '/assets/svg/chevron-down.svg'"
          ></inline-svg>
        </span>
      </template>

      <template #cell(history)="row">

        <div v-if="row.item.history.length > 0">
          <a href="#" class="btn btn-icon btn-light btn-sm">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/history.svg"></inline-svg>
            </span>
          </a>
        </div>
        <div v-else>
          <p>-</p>
        </div>

      </template>

      <template #cell(generate_file)="row">
        <b-button @click.prevent="generate_file_clicked(row.item.type)" variant="primary">{{$t('PAGES.FAITH.GENERATE')}}</b-button>
      </template>

      <template #cell(selected)="row">
        <v-checkbox
          :ripple="false"
          v-model="row.item.selected"
          class="my-0 py-0"
          color="#3699FF"
          hide-details
        ></v-checkbox>
      </template>

    </b-table>

    <div v-if="files.length === 0">
      {{ $t('COMPANY_PERIOD.NO_ROWS_FOUND') }}
    </div>

  </div>

</template>
<style lang="css" scoped>
.pagination-select {
  height: 32px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 12px;
  max-width: 100px;
}

:deep .b-table-details td, tr {
  padding: 0px;
}

:deep .w-type {
  width: 290px;
}

:deep .w-period {
  width: 120px;
}

:deep .w-created {
  width: 200px;
}

:deep .w-username {
  width: 180px;
}

:deep .w-generated {
  width: 240px;
}

:deep .w-history {
  width: 80px;
}



</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import 'vue-select/dist/vue-select.css';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import ExportFilesRowDetails from '@/view/pages/ml/faith/export/ExportFilesRowDetails.vue';


export default {
  name: "export-files-table",
  mixins: [ toasts ],
  props: ["files","show_create"],
  emits: ['on_generate_file_clicked', 'create_company_period', 'select_company', 'select_company_period', 'delete_company_period'],
  components: {
    ExportFilesRowDetails
  },
  watch: {

  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId']),
  },
  mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }
  },
  methods: {

    is_empty(name) {
      if (!name || name.length === 0 || name === ' ') {
        return true;
      }
      return false;
    },


    generate_file_clicked(type) {
      this.$emit('on_generate_file_clicked', type);
    },

    handle_row_clicked(row, index, e) {
      if (row._showDetails) {
        row._showDetails = false;
      }
      else {
        row._showDetails = true;
      }
      // Vue3 compatability for Vue.set
      row = { ...row };
    },

    add_new_row(item) {
      item.newRow = this.newRowValue;
      // Vue3 compatability for Vue.set
      item = { ...item };

      this.newRowValue = "";
    },

    download_company_period_report_clicked(id) {
      this.toastr('danger', this.$t('COMMON.ERROR'), 'Ej implementerat');
    },

    replace_null_empty(value) {
      if (value === null || value === undefined) {
        return '';
      }

      return value;
    },

    create_company_period_clicked() {
      this.$emit('create_company_period', company_period_id);
    },

    select_company_clicked(company_id) {
      this.$emit('select_company', company_id);
    },

    select_company_period_clicked(company_period_id) {
      this.$emit('select_company_period', company_period_id);
    },

    delete_company_period_clicked(company_period_id) {
      this.$emit('delete_company_period', company_period_id);
    }

  },
  data() {
    return {
      newRowValue: "",
      filters: {
        text: ''
      },
      total_rows: 0,
      per_page: 100,
      current_page: 1,
      per_page_options: [
        100, 250, 500
      ],
      headers: [
        {
          formatter: (_, __, item) => {
            return this.$t('PAGES.FAITH.' + item.type);
          },
          key: 'type',
          label: this.$t('PAGES.FAITH.FILE_TYPE'),
          sortable: false,
          thClass: 'pl-7 align-middle w-type',
          tdClass: 'pl-7 align-middle w-type'
        },
        {
          key: 'period_id',
          label: this.$t('PAGES.FAITH.FOR_PERIOD'),
          sortable: false,
          thClass: 'pl-7 align-middle w-period',
          tdClass: 'pl-7 align-middle w-period',
          formatter: (_, __, item) => {
            return item.period.year;
          },
        },

        {
          key: 'created_at',
          label: this.$t('PAGES.FAITH.GENERATED_AT'),
          sortable: false,
          thClass: 'pl-7 align-middle w-created',
          tdClass: 'pl-7 align-middle w-created',
          formatter: (_, __, item) => {
            return '';
          },
        },
        {
          key: 'user',
          label: this.$t('PAGES.FAITH.USER_NAME'),
          sortable: false,
          thClass: 'pl-7 align-middle w-username',
          tdClass: 'pl-7 align-middle w-username',
          formatter: (_, __, item) => {
            return '';
          },
        },

        {
          key: 'history',
          label: this.$t('PAGES.FAITH.HISTORY'),
          sortable: false,
          thClass: 'pl-7 align-left w-history',
          tdClass: 'pl-7 align-left w-history',
        },

        {
          key: 'generate_file',
          label: this.$t('PAGES.FAITH.GENERATE_FILE'),
          sortable: false,
          thClass: 'pl-7 align-middle w-generate',
          tdClass: 'pl-7 align-middle w-generate'
        },
        {
          key: 'expand',
          label: "",
          sortable: false
        },
      ],

    };
  }
};
</script>
