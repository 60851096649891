<template>
  <div id="corrections-page">

    <MemberEditorCompactModal 
      ref="member-editor-modal"
      :member_id="member_id"
      @created="empty"
      @updated="empty"
      @deleted="empty"
    />

    <CorrectionsTable
      class="mb-32"
      :items="members_to_remove"
      @skv_job_finished="skv_correction_job_finished"
      @member_clicked="member_clicked"
    />

    <MemberJobsFileTable 
      :files="files"
    />
  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import CorrectionsTable from '@/view/pages/ml/faith/member_jobs/CorrectionsTable.vue';
import MemberJobsFileTable from '@/view/pages/ml/faith/member_jobs/MemberJobsFileTable.vue';
import MemberEditorCompactModal from '@/view/components/member_editor_v2/MemberEditorCompactModal.vue';

export default {
  name: 'corrections-page',
  mixins: [ toasts ],
  components: {
    CorrectionsTable,
    MemberJobsFileTable,
    MemberEditorCompactModal
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods']),
   
  },
  mounted() {
    this.load_correction_members();
    this.load_skv_files();
  },
  data() {
    return {
      members_to_remove: [],
      files: [],
      member_id: null
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
      this.load_correction_members();
      this.load_skv_files();
    }

  },
  methods: {
    empty(){},

    member_clicked(member_id) {
      
      this.member_id = member_id;
      this.$refs['member-editor-modal'].show(member_id);
    },

    get_options() {
      
      const options = {};
      
      options.statuses = [ 'MEMBER','EXITED' ];
      options.skv_period_id = this.currentPeriodId;
      options.skv_statuses = [ 'PEN_DELETED' ]

      return options;
    },


    async skv_correction_job_finished(job) {
      const loader = this.$loading.show();

      await this.load_correction_members();
      await this.load_skv_files();

      setTimeout(()=>{
        loader & loader.hide();
      }, 4000);
    },

    async load_correction_members() {
      try {

        const options = this.get_options();

        const res = await axios.post(`/skv/members/skv_status/pagination`, {
          options: options,
          orderBy: {
            created_at: 'desc'
          },
          limit: 10000000,
          page: 0
        });

        if (res.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.UNABLE_LOAD'));
          return;  
        }

        this.members_to_remove = res.data;
      }
      catch (err) {
        console.error('load_correction_members errors', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.UNABLE_LOAD'));
      }
    },

    async load_skv_files() {
      try {
        const res = await axios.get(`/skv/files/${this.currentPeriodId}`);

        if (res.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.UNABLE_LOAD'));
          return;  
        }

        this.files = res.data.filter(item => item.type === 'SKV_EXPORT_CORRECTIONS');
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.UNABLE_LOAD'));
      }
    }
  }
};
</script>
